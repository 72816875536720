import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/utilitary.css'
import '../styles/style.css'
import { Link } from "gatsby"
import mainfoto from "../images/imagem-section12.png" // Tell webpack this JS file uses this image
import cardfoto from "../images/carne2.png" // Tell webpack this JS file uses this image
import dificult from "../images/dificuldade-vector.png" // Tell webpack this JS file uses this image
import dificult2 from "../images/diff-media.png" // Tell webpack this JS file uses this image
import dificult3 from "../images/diff-alta.png" // Tell webpack this JS file uses this image
import time from "../images/time-vector.png" // Tell webpack this JS file uses this image
import time20 from "../images/tempo-20.png" // Tell webpack this JS file uses this image
import time25 from "../images/tempo-25.png" // Tell webpack this JS file uses this image
import time40 from "../images/tempo-40.png" // Tell webpack this JS file uses this image
import time30 from "../images/tempo-30.png" // Tell webpack this JS file uses this image
import carnefly from "../images/meatshadow.png" // Tell webpack this JS file uses this image
import angus from "../images/angus-icon.png" // Tell webpack this JS file uses this image
import angusinit from "../images/angus-init.png" // Tell webpack this JS file uses this image
import receita1 from "../images/receita1.jpg" // Tell webpack this JS file uses this image
import receita2 from "../images/PicanhaGrelhada2.jpg" // Tell webpack this JS file uses this image
import receita3 from "../images/receita3.jpg" // Tell webpack this JS file uses this image
import bifetira from "../images/bifetira.jpg" // Tell webpack this JS file uses this image
import chorizo from "../images/chorizo.jpg" // Tell webpack this JS file uses this image
import hamb from "../images/hamb.jpg" // Tell webpack this JS file uses this image

import logoblack from "../images/logo.png" // Tell webpack this JS file uses this image
import raca from "../images/pic2.png" // Tell webpack this JS file uses this image
import maturacao from "../images/pic1.png" // Tell webpack this JS file uses this image
import sust from "../images/pic3.png" // Tell webpack this JS file uses this image
import logo from "../images/logo2.png" // Tell webpack this JS file uses this image
import certis from "../images/certis.png" // Tell webpack this JS file uses this image
import Helmet from "react-helmet"
import tabua from "../images/tabua2.png" // Tell webpack this JS file uses this image
import scrollTo from 'gatsby-plugin-smoothscroll';

import video from "../images/video.mp4"




const IndexPage = () => (
<Layout>


   <div id="section-1" className="green-bg">

      <div className="wrapper-container">
         <div className="left-col">
            <div  className="left-element" data-sal="slide-up" data-sal-delay="400" data-sal-duration="800" data-sal-easing="ease">
               <div className="slogan ">
                  <p className="old-press titulo">Sabor da <br></br> América do Sul</p>
                  <p className="desc sneak-regular">Uma experiência memorável. <br></br>Uma viagem pelas extensas e ricas planícies da América do Sul.</p>
                  <button onClick={() => scrollTo('#section-2')} className="btn-sugestoes absara">Descubra as nossas sugestões</button>
                  <div className="logos">
                     <div className="cert">
                  <img  src={certis} alt="" />
                  </div>
                  <div className="angus">
                  <img  src={angusinit} alt="" />
                  </div>

                  </div>
               </div>
            </div>
         </div>
         <div className="right-col">
            <div className="right-element" data-sal="slide-up" data-sal-delay="400"  data-sal-duration="800" data-sal-easing="ease">
            <img  src={tabua} alt="" />

            </div>
         </div>
      </div>
   </div>
   <div id="section-2" className="green-bg">
      <div className="wrapper-container">
         <div className="left-col">
         <Link to="/naco-chimichurri/">
            <div className="card-recipe">
               <div className="wrapper">
                  <img className="imagem-capa" src={receita1} alt="Logo" />
                  <div className="devider">
                  </div>
                  <div className="titulo absara">
                     Naco com Molho Chimichurri
                  </div>
                  <div className="descr sneak-regular">
                  O naco com molho de chimichurri leva-o por uma viagem aos paladares tipicamente argentinos. 
                  </div>
                  <div className="caracts">
                     <div className="time-vector">
                        <img src={time20} alt="Logo" />
                     </div>
                     <div className="diff-vector">
                        <img src={dificult} alt="Logo" />
                     </div>
                  </div>
               </div>
            </div>
            </Link>
         </div>
         <div className="center-col">
         <Link to="/picanha-grelhada/">
            <div className="card-recipe">
               <div className="wrapper">
                  <img className="imagem-capa" src={receita2} alt="Logo" />
                  <div className="devider">
                  </div>
                  <div className="titulo absara">
                     Picanha Grelhada com Vinagrete (Molho à Campanha)
                  </div>
                  <div className="descr sneak-regular">
                  A acidez do vinagrete é o sabor ideal para combinar com a carne tenra e suculenta da picanha grelhada.
                  </div>
                  <div className="caracts">
                     <div className="time-vector">
                        <img src={time30} alt="Logo" />
                     </div>
                     <div className="diff-vector">
                        <img src={dificult2} alt="Logo" />
                     </div>
                  </div>
               </div>
            </div>
            </Link>
         </div>
         <div className="right-col">
         <Link to="/bife-portuguesa/">
            <div className="card-recipe">
               <div className="wrapper">
                  <img className="imagem-capa" src={receita3} alt="Logo" />
                  <div className="devider">
                  </div>
                  <div className="titulo absara">
                     Bife à Portuguesa
                  </div>
                  <div className="descr sneak-regular">
                  Com origem na América do Sul, a carne tenra e saborosa, conquista a gastronomia portuguesa e seduz-nos através de uma experiência típica e tradicional.
                  </div>
                  <div className="caracts">
                     <div className="time-vector">
                        <img src={time30} alt="Logo" />
                     </div>
                     <div className="diff-vector">
                        <img src={dificult3} alt="Logo" />
                     </div>
                  </div>
               </div>
            </div>
            </Link>
         </div>
         <div className="right-col">
         <Link to="/bife-tira/">
            <div className="card-recipe">
               <div className="wrapper">
                  <img className="imagem-capa" src={bifetira} alt="Logo" />
                  <div className="devider">
                  </div>
                  <div className="titulo absara">
                     Bife de Tira com Arroz e Feijão Preto
                  </div>
                  <div className="descr sneak-regular">
                  Feijão preto típico da gastronomia carioca é a guarnição mais desejável para o bife de tira de qualidade superior.
                  </div>
                  <div className="caracts">
                     <div className="time-vector">
                        <img src={time25} alt="Logo" />
                     </div>
                     <div className="diff-vector">
                        <img src={dificult} 
                        lt="Logo" />
                     </div>
                  </div>
               </div>
            </div>
            </Link>
         </div>
         <div className="right-col">
         <Link to="/bife-chorizo/">
            <div className="card-recipe">
               <div className="wrapper">
                  <img className="imagem-capa" src={chorizo} alt="Logo" />
                  <div className="devider">
                  </div>
                  <div className="titulo absara">
                     Bife do Chorizo com Polenta Grelhada e Salsa Criolla
                  </div>
                  <div className="descr sneak-regular">
                  O tenro Bife de Chorizo aliado à crocância da polenta grelhada são a conjugação de texturas mais apetecível.
                  </div>
                  <div className="caracts">
                     <div className="time-vector">
                        <img src={time} alt="Logo" />
                     </div>
                     <div className="diff-vector">
                        <img src={dificult3} alt="Logo" />
                     </div>
                  </div>
               </div>
            </div>
            </Link>
         </div>
         <div className="right-col">
         <Link to="/hamburguer/">
            <div className="card-recipe">
               <div className="wrapper">
                  <img className="imagem-capa" src={hamb} alt="Logo" />
                  <div className="devider">
                  </div>
                  <div className="titulo absara">
                     Hambúrguer com Cogumelos Salteados e Molho Argentino
                  </div>
                  <div className="descr sneak-regular">
                  Cada camada deste hambúrguer recheado de sabor, envolve-o numa experiência gastronómica latina.
                  </div>
                  <div className="caracts">
                     <div className="time-vector">
                        <img src={time20} alt="Logo" />
                     </div>
                     <div className="diff-vector">
                        <img src={dificult2} alt="Logo" />
                     </div>
                  </div>
               </div>
            </div>
            </Link>
         </div>
   
      </div>
   </div>
   <div id="section-3" className="soft-green-bg">
      <div className="wrapper-container">
      </div>
   </div>
       <div id="rec-section-video">
        <div className="wrapper">
            <div className="left-col">
            <video className="video-class" preload='auto' controls autoPlay loop playsInline muted >
           <source src={video} type="video/mp4" />
         </video>
            </div>
            <div className="right-col">
                    <div className="wrapper sneak-regular">
                        <div className="conteudo">
                        <img className="img" src={angus} alt="Logo" /><br></br>
                        A carne oriunda da América do Sul merece uma confeção cuidadosa que garanta toda a suculência e maciez, tornando o seu sabor irresistível.
                        </div>
                        
                    </div>
                </div>
        </div>
    </div>
   <div id="section-4" className="">
      <div className="wrapper-container">
        <div className="left-col">
          <div className="wrapper">

          {/* <div className="titulo absara">
              Resultado de animais<br></br> da raça Angus
            </div> */}
          </div>
          

        </div>
        <div className="right-col">
        <div className="wrapper">
          <div className="titulo absara">
          <img src={logoblack} alt="Logo" />

          </div>
          <div className="desc sneak-regular">
          
Martín Fierro será o mais conhecido Gaúcho Argentino. Esta personagem criada pelo poeta José Hernández, tornou-se um símbolo Argentino, representando o papel determinante dos Gaúchos na construção do País.

Na Argentina em ruptura com a Espanha, o poema épico enaltece a figura histórica do Gaúcho. Um expressar da condição humana face à adversidade, numa busca pela liberdade e contrariando o destino mais certo.

          </div>
          </div>
        </div>

      </div>
   </div>
   <div id="section-5" className="">
      <div className="wrapper-container">
            <div className="left-col">
              <div className="wrapper">
                <div className="imagem">
                <img src={raca} alt="Logo" />

                </div>
                <div className="texto sneak-regular">
                A nossa carne é proveniente de animais Black Angus. Caracterizado pela ausência de “cornos”, pela pelagem negra e por temperamento dócil, os animais Black Angus reúnem uma predisposição genética para a produção de carne de alta qualidade.

                </div>
              </div>

            </div>
            <div className="center-col">
            <div className="wrapper">
                <div className="imagem">
                <img src={maturacao} alt="Logo" />

                </div>
                <div className="texto sneak-regular">
                Para uma maior tenrura, esta carne antes de chegar ao seu prato foi maturada por um período minímo de 5 semanas.

                </div>
              </div>

            </div>
            <div className="right-col">
            <div className="wrapper">
                <div className="imagem">
                <img src={sust} alt="Logo" />

                </div>
                <div className="texto sneak-regular">
                A nossa embalagem é formada por cerca de 80% de cartão proveniente de fontes responsáveis certificada FSC.
                </div>
              </div>

            </div>  

      </div>
   </div>


</Layout>
)
export default IndexPage